
import { defineComponent, reactive, toRefs, ref } from 'vue'
import CUpload from '@/components/c-upload'
import { ElMessage } from 'element-plus'
import { setEditEnterprise, getDetailOfEnterprise } from './api'
import { useRouter } from 'vue-router'
import { getToken } from '@/utils/auth'
import { setBaseUrl } from '@/config/url'
import { rules, economicsStatusMap, economicsTypeMap, customerTypeOpt } from './config'
import moment from 'moment'
import { regionData } from 'element-china-area-data'

export default defineComponent({
  components: { CUpload },
  setup() {
    const router = useRouter()
    const data = reactive({
      formData: ref<any>({
        systemName: '虎鲸车管家'
      }),
      accountAddressOptions: regionData,
      rules,
      economicsStatusMap,
      economicsTypeMap,
      customerTypeOpt,
      action:
        setBaseUrl().uploadUrl + '/ccclubs-file-center/common/file/uploadFile',
      headers: {
        token: getToken()
      }
    })
    const managePermissionJpgList = ref([])
    const businessLicenseJpgList = ref([])
    const getEnterpriseDetail = (uuid: object) => {
      getDetailOfEnterprise(uuid).then(res => {
        data.formData = res.data
        data.formData.contactsAreaCode =
          data.formData.contactsAreaCode &&
          data.formData.contactsAreaCode.split(',')

        if ((data.formData as any).managePermissionJpg) {
          managePermissionJpgList.value.push({
            name: '经营许可证',
            url: res.data.managePermissionJpg,
            status: 'edit',
            isImg: true
          })
        }
        if ((data.formData as any).businessLicenseJpg) {
          businessLicenseJpgList.value.push({
            name: '营业执照',
            url: res.data.businessLicenseJpg,
            status: 'edit',
            isImg: true
          })
        }
      })
    }
    const uploadBLSuccess = file => {
      ;(data.formData as any).businessLicenseJpg = file.response.data.filename
    }
    const uploadMPSuccess = file => {
      ;(data.formData as any).managePermissionJpg = file.response.data.filename
    }
    const uploadBLRemove = () => {
      if ((data.formData as any).businessLicenseJpg) {
        ;(data.formData as any).businessLicenseJpg = null
      }
    }
    const uploadMPRemove = () => {
      if ((data.formData as any).managePermissionJpg) {
        ;(data.formData as any).managePermissionJpg = null
      }
    }
    const changeManageTimeStar = o => {
      if (o) {
        ;(data.formData as any).manageTimeStar = moment(
          (data.formData as any).manageTimeStar
        ).format('YYYY-MM-DD')
      }
    }
    const changeManageTimeEnd = o => {
      if (o) {
        ;(data.formData as any).manageTimeEnd = moment(
          (data.formData as any).manageTimeEnd
        ).format('YYYY-MM-DD')
      }
    }
    const changeManageTimeFirst = o => {
      if (o) {
        ;(data.formData as any).manageTimeFirst = moment(
          (data.formData as any).manageTimeFirst
        ).format('YYYY-MM-DD')
      }
    }
    const back = () => router.back()
    const formRef = ref<HTMLElement | any>(null)
    const creatSumbit = (): void => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          data.formData.contactsAreaCode =
            data.formData.contactsAreaCode &&
            data.formData.contactsAreaCode.join(',')
          setEditEnterprise(data.formData).then(() => {
            ElMessage.success('修改成功')
            back()
          })
        } else {
          ElMessage.warning('表单校验不正确')
        }
      })
    }

    getEnterpriseDetail(router.currentRoute.value.query)
    return {
      formRef,
      ...toRefs(data),
      managePermissionJpgList,
      businessLicenseJpgList,
      creatSumbit,
      getEnterpriseDetail,
      back,
      uploadBLSuccess,
      uploadMPSuccess,
      uploadBLRemove,
      uploadMPRemove,
      changeManageTimeStar,
      changeManageTimeFirst,
      changeManageTimeEnd
    }
  },
  methods: {
    // 切换城市赋值
    handleCContactsArea() {
      this.formData.contactsArea = this.$refs.contactsAreaCode
        .getCheckedNodes()[0]
        .text.replaceAll(' / ', '')
    }
  }
})
